import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { BREADCRUMB_TOKENIZER, BreadcrumbTokenizer } from './breadcrumb.token';

@Injectable()
export class BreadcrumbService implements OnDestroy {
  subscriptions: Subscription[] = [];
  path$ = this.tokenizer.currentPath$;

  constructor(@Inject(BREADCRUMB_TOKENIZER) private tokenizer: BreadcrumbTokenizer) {
    this.subscriptions.push(this.tokenizer.connect());
  }

  reset() {
    this.tokenizer.reset();
  }

  ngOnDestroy() {
    this.subscriptions.filter(s => !!s && !s.closed).forEach(s => s.unsubscribe());
  }
}
